import toFormData from "@utils/toFormData";
import { action, computed, thunk } from "easy-peasy";
import { DEFAULT_PAGINATION } from "../../components/ChallengeApp/Constants";
import HttpWrapper from "../httpRequester";
import { deNormalizeCollection, normalizeCollection2 } from "../normalizerSchema";

const requester = new HttpWrapper("listings");
const communitiesRequester = new HttpWrapper("communities");
const snapshotRequest = new HttpWrapper("snapshot_app");
const activityAppRequester = new HttpWrapper("activity_app");
const favoriteRequester = new HttpWrapper("favorite_listings");

const toListingFormat = (listing) => {
  let formData = new FormData();
  let data = listing;
  if (!data.scenario_id) delete data.scenario_id;

  return toFormData("listing", data, formData);
};

const uniqueArray = (arr) => Array.from(new Set(arr));

const attributes = {
  managableIDs: [],
  listingIDs: [],
  managableListings: [],
  contribution_apps: [],
  allListings: [],
  listingsNetworkCount: null,
  allListingIDs: [],
  listingsPagination: DEFAULT_PAGINATION,
  allListingsPagination: DEFAULT_PAGINATION,
  managablePagination: DEFAULT_PAGINATION,
};

const listResolverGenerator = (field) => (state, storeState) => ({ ids: state[field], entities: storeState.entities });
const managableResolver = listResolverGenerator("managableIDs");
const listingsResolver = listResolverGenerator("listingIDs");
const allListingsResolver = listResolverGenerator("allListingIDs");

const computedListings = ({ ids, entities }) => {
  return deNormalizeCollection(ids, entities, "listing") || [];
};

const computedProps = {
  managableListings: computed([managableResolver], computedListings),
  listings: computed([listingsResolver], computedListings),
  allListings: computed([allListingsResolver], computedListings),
};

const actions = {
  updateOrders: thunk((_, { ids, orders }) => {
    return requester.patch("/update_order", { ids, orders });
  }),
  addSpreadSheet: thunk((_, { id, ...import_job }) => {
    return activityAppRequester.post("/import_jobs", { listing_id: id, import_job });
  }),
  deleteSpreadsheet: thunk((_, { id }) => {
    return activityAppRequester.delete(`/import_jobs/${id}`);
  }),
  deleteListing: thunk(({ setProperty }, id, { getState }) => {
    return requester.delete(`/${id}`).then(() => {
      const listingIDs = getState().managableIDs.filter((xid) => xid != id);
      setProperty({ field: "managableIDs", data: listingIDs });
    });
  }),
  setProperty: action((state, { field, data }) => {
    state[field] = data;
  }),
  normalize: thunk((actions, data, { getStoreActions }) => {
    const { setEntities } = getStoreActions().entities;
    const { result, entities } = normalizeCollection2(data, "listing");
    setEntities({ entities });

    return result;
  }),
  getActiveListingsRequest: thunk(
    (
      { paginatedResponseHandler },
      { id, keyword, sort_type, sort_direction, community_tag_ids, page = 1, ...args }
    ) => {
      return communitiesRequester
        .get(`${id}/active_listings`, { id, keyword, sort_type, sort_direction, page, community_tag_ids, ...args })
        .then(({ listings, pagination }) => {
          paginatedResponseHandler({
            listings,
            pagination,
            idsField: "listingIDs",
            paginationField: "listingsPagination",
          });
        });
    }
  ),
  getListingsRequest: thunk(
    (
      { paginatedResponseHandler },
      { id, keyword, sort_type = "reward", sort_direction = "asc", page = 1, status = "active" }
    ) => {
      return communitiesRequester
        .get(`${id}/listings`, { keyword, sort_type, sort_direction, page, status })
        .then(({ listings, pagination }) => {
          paginatedResponseHandler({
            listings,
            pagination,
            idsField: "managableIDs",
            paginationField: "managablePagination",
          });
        });
    }
  ),
  getListingDetails: thunk((_, id) => {
    return requester.get(`${id}`);
  }),
  createListing: thunk(
    (actions, { community_id, contribution_app: contribution_app_key, draft = false, ...listing }) => {
      const url = draft ? `${community_id}/create_draft_listing` : `${community_id}/create_listing`;
      const data = toListingFormat(listing);
      data.append("contribution_app_key", contribution_app_key);

      return communitiesRequester.post(url, data);
    }
  ),
  paginatedResponseHandler: thunk(
    ({ normalize, setProperty }, { listings, pagination, idsField, paginationField }, { getState }) => {
      const ids = normalize(listings);
      const oldIDs = getState()[idsField];
      const data = pagination.page > 1 ? uniqueArray([...oldIDs, ...ids]) : ids;

      setProperty({ field: idsField, data: data });
      setProperty({ field: paginationField, data: pagination });
    }
  ),
  updateListing: thunk((actions, listing) => {
    const url = `${listing.id}`;
    const data = toListingFormat(listing);
    return requester.patch(url, data);
  }),
  getAllActiveListingsRequest: thunk(
    ({ paginatedResponseHandler }, { keyword, sort_type = "reward", sort_direction = "asc", page = 1, community_tag_ids, ...args }) => {
      return requester.get(`/active`, { keyword, sort_type, sort_direction, page, community_tag_ids, ...args }).then(({ listings, pagination }) => {
        paginatedResponseHandler({
          listings,
          pagination,
          idsField: "allListingIDs",
          paginationField: "allListingsPagination",
        });
      });
    }
  ),
  getSnapshotDetails: thunk((actions, activityId) => {
    return snapshotRequest.get(`/activities/${activityId}`);
  }),
  getActivityDetails: thunk((actions, activityId) => {
    return activityAppRequester.get(`/activities/${activityId}`);
  }),
  getContributionApps: thunk(({ setContributionApps }) => {
    return requester.get(`contribution_apps`).then(({ contribution_apps }) => setContributionApps(contribution_apps));
  }),
  getListingsNetworkCount: thunk(({ setListingsNetworkCount }, community_id) => {
    return communitiesRequester
      .get(`${community_id}/listings_count_per_network`)
      .then(({ stats }) => setListingsNetworkCount(stats));
  }),
  setContributionApps: action((state, contribution_apps) => {
    state.contribution_apps = contribution_apps;
  }),
  editRequest: thunk((_, id) => {
    return requester.get(`${id}/edit`);
  }),
  archiveRequest: thunk(({ setProperty }, id, { getState }) => {
    return requester.patch(`${id}/archive`).then(() => {
      const listingIDs = getState().managableIDs.filter((xid) => xid != id);
      setProperty({ field: "managableIDs", data: listingIDs });
    });
  }),
  activateRequest: thunk(({ setProperty }, id, { getState }) => {
    return requester.patch(`${id}/activate`).then(() => {
      const managableIDs = getState().managableIDs.filter((xid) => xid != id);

      setProperty({ field: "managableIDs", data: managableIDs });
    });
  }),
  addFavoriteRequest: thunk(({ normalize }, id) => {
    return favoriteRequester.post("", { favorite_listing: { listing_id: id } }).then(() => {
      const listing = { id, favorite: true };
      normalize([listing]);
    });
  }),
  setListingsNetworkCount: action((state, listingsNetworkCount) => {
    state.listingsNetworkCount = listingsNetworkCount;
  }),
  loadMoreAllListings: action((state, allListings) => {
    state.allListings = [...state.allListings, ...allListings];
  }),
  setAllListingsPagination: action((state, pagination) => {
    state.allListingsPagination = pagination;
  }),
  removeFavoriteRequest: thunk(({ normalize }, id) => {
    return favoriteRequester.delete(`remove/${id}`).then(() => {
      const listing = { id, favorite: false };
      normalize([listing]);
    });
  }),
};

const listings = {
  ...attributes,
  ...computedProps,
  ...actions,
};

export default listings;
