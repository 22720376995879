import { Box, Button, IconSvg, Text, TextArea } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useParams } from "react-router";
import styled from "styled-components";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import { makeConditionElements } from "../SubmissionModal/Condition";

const AcceptStep = ({
  id,
  hideModal,
  username,
  message,
  goToReject,
  contribution_claim_id,
  verification_code,
  activity_name,
  conditions,
  reviewer_instructions,
  submission_urls,
  isMobile,
}) => {
  const { approveClaimRequest } = useStoreActions((actions) => actions.contributions);
  const { loading } = useStoreState((state) => state.contributions);

  const conditionElements = makeConditionElements(conditions, false);
  const { showToast, dangerToast } = useStoreActions((actions) => actions.toasts);
  const onCopyCode = useCopyToClipboard(verification_code, "Your code has been copied.");

  const onClick = () => {
    approveClaimRequest({
      id,
      contribution_claim_id,
    })
      .then(refreshPendingContributions)
      .then(() => {
        showToast({
          content: "Contribution claim was approved successfully!",
          dismissTime: 5000,
        });
        hideModal();
      })
      .catch(({ response }) => {
        dangerToast({
          content: response.data.message,
          dismissTime: 5000,
        });
      });
  };

  const { getPendingContributions } = useStoreActions((actions) => actions.pendingContributions);
  const { community_id } = useParams();
  const refreshPendingContributions = () => {
    getPendingContributions({ community_id });
  };
  return (
    <Box flex direction="column" spacing={isMobile ? "my-4" : "my-5"}>
      <Text typography="heading/medium" color="02Primary500" spacing="mb-4" bold>
        Submitted Contribution
      </Text>
      {!isMobile && <Box bgColor="02PrimaryGrey300" spacing={isMobile ? "mb-4" : "mb-5"} height="1px" width="100%" />}
      <Text typography="body/large" color="02Primary500" spacing={isMobile ? "mb-4" : "mb-5"} extraBold>
        {activity_name}
      </Text>
      <Box>
        <Text typography="body/medium" color="02Primary500" spacing="mb-3" extraBold>
          Conditions
        </Text>
        <WrapperConditions>{conditionElements}</WrapperConditions>
      </Box>
       {!isMobile && <Box bgColor="02PrimaryGrey300" spacing={isMobile ? "my-4" : "my-5"} height="1px" width="100%" />}
      <Text typography="body/medium" color="02Primary700" spacing="mb-3" extraBold>
        Reviewer Instructions
      </Text>
      <Box spacing="mb-2">
        <InstructionsText
          typography="body/medium"
          color="02Primary700"
          dangerouslySetInnerHTML={{ __html: reviewer_instructions }}
        />
      </Box>
      <CopyWrapper pointer onClick={onCopyCode} color="02Primary500">
        <Text>Personal Code:</Text>
        <Text bold>{verification_code}</Text>
        <IconSvg width="14px" icon="copy" />
      </CopyWrapper>
      {submission_urls.length > 0 ||
        (message && <Box bgColor="02PrimaryGrey300" spacing={isMobile ? "my-4" : "my-5"} height="1px" width="100%" />)}
      <Box bgColor="02PrimaryGrey300" spacing={isMobile ? "mt-4" : "mt-5"} height="1px" width="100%" />
      {submission_urls.length > 0 && (
        <>
          <Text typography="body/medium" color="02Primary500" spacing="mt-4 mb-3" bold>
            Contribution URL
          </Text>
          <Box flex direction="column" gap="16px">
            {submission_urls.map((url, index) => {
              return (
                <StyledInput key={`submissionUrl-${index}`} href={url.value}>
                  {" "}
                  <Text>{url.value} </Text>{" "}
                </StyledInput>
              );
            })}
          </Box>
        </>
      )}

      {message && (
        <>
          <Text typography="body/medium" color="02Primary500" spacing="mt-5 mb-3" bold>
            Message
          </Text>
          <StyledTextArea
            disabled
            value={message}
            color="02Primary500"
            bgColor="02PrimaryGrey50"
            borderColor="02PrimaryGrey300"
            rows="3"
          />
        </>
      )}
      <Box bgColor="02PrimaryGrey300" spacing={isMobile ? "my-4" : "my-5"} height="1px" width="100%" />
      <Box direction={isMobile && "column-reverse"} flex gap="1rem">
        <Button
          bold
          height="56px"
          width={isMobile ? "100%" : "161px"}
          kind="outline"
          borderRadius="40px"
          variant="red"
          onClick={goToReject}
          modal={true}
        >
          Reject
        </Button>
        <Button bold height="56px" grow={1} borderRadius="40px" onClick={onClick} modal={true}>
          Accept Contribution
        </Button>
      </Box>
      {isMobile && <Box bgColor="02PrimaryGrey300" spacing="mt-5" height="1px" width="100%" />}
    </Box>
  );
};

const CopyWrapper = styled(Box)`
  display: flex;
  height: 44px;
  width: 306px;
  align-items: center;
  justify-content: center;
  gap: 6px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors["02PrimaryGrey300"]};
  background: ${({ theme }) => theme.colors["02PrimaryGrey50"]};
`;
const StyledTextArea = styled(TextArea)`
  text-area {
    max-height: 89px;
  }
`;

const StyledInput = styled.a`
  cursor: pointer;
  border-radius: 12px;
  max-height: 56px;
  border: 1px solid ${({ theme }) => theme.colors["02PrimaryGrey300"]};
  color: ${({ theme }) => theme.colors["01Link"]};
  text-decoration: underline;
  font-weight: 600;
  display: flex;
  align-items: center;
  padding: 18px 16px;
  & > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
const WrapperConditions = styled(Box)`
  display: grid;
  grid-template-columns: auto auto;
  gap: 12px;
`;

const InstructionsText = styled(Text)`
  white-space: break-spaces;
  & > * {
    padding: 0;
    margin: 0;
  }
`;
export default AcceptStep;
