import { useStoreActions, useStoreState } from "easy-peasy";
import { virtualPageView } from "./hooks/useVirtualPageView";
import AbandonChallengeModal from "./modals/AbandonChallengeModal";
import AboutUsModal from "./modals/AboutUsModal/index";
import ActivityDetailsModal from "./modals/ActivityDetailsModal";
import AddListingConditionModal from "./modals/AddListingConditionModal";
import { ActivateListingModal, ArchiveListingModal } from "./modals/ArchiveListingModal";
import { BeforeYouLeaveCommunityModal, BeforeYouLeaveSeasonModal } from "./modals/BeforeYouLeaveModal";
import CancelDonationModal from "./modals/CancelDonationModal";
import ClaimRewardFlowModal from "./modals/ClaimRewardFlowModal";
import CommitToChallengeModal from "./modals/CommitToChallengeModal";
import BenefitsCommunityCreationModal from "./modals/CommunityEditor/BenefitsCommunityCreationModal";
import CommunityEditorModal from "./modals/CommunityEditorModal";
import CompleteSeasonModal from "./modals/CompleteSeasonModal";
import ConnectEmailModal from "./modals/ConnectEmailModal";
import ConnectSocialMediasModal from "./modals/ConnectSocialMediasModal";
import DisconnectSocialProfileModal from "./modals/DisconnectSocialProfileModal";
import DiscourseConnectModal from "./modals/DiscourseConnectModal";
import DonateModal from "./modals/DonateModal";
import FilterModal from "./modals/FilterModal";
import FundChallengeModal from "./modals/FundChallengeModal";
import GitcoinConnectModal from "./modals/GitcoinConnectModal";
import InviteAdministratorsModal from "./modals/InviteAdministratorsModal";
import ListingEditorModal from "./modals/ListingEditorModal";
import ListingShowModal from "./modals/ListingShowModal";
import ManagePendingContributionModal from "./modals/ManagePendingContributionModal";
import PinCodeVerificationModal from "./modals/PinCodeVerificationModal";
import PublishChallengeConfirmationModal from "./modals/PublishChallengeConfirmationModal";
import { PublishCommunityModal, PublishSeasonModal } from "./modals/PublishModals";
import RemoveAdminModal from "./modals/RemoveAdminModal";
import RewardFoundModal from "./modals/RewardFoundModal";
import SeasonEditorModal from "./modals/SeasonEditorModal";
import SeasonEligibilityModal from "./modals/SeasonEligibilityModal";
import SelectFundingWalletModal from "./modals/SelectFundingWalletModal";
import ShareSocialMediaModal from "./modals/ShareSocialMediaModal";
import SignInFlowModal from "./modals/SignInFlowModal";
import ConfirmContributionModal from "./modals/SubmissionModal/ConfirmContributionModal";
import UpdateProfileFlowModal from "./modals/UpdateProfileFlowModal";
import UpdateProfileModal from "./modals/UpdateProfileModal";
import WalletConnectorModal from "./modals/WalletConnectorModal";
import WalletSigninFlow from "./modals/WalletSigninFlow";
import AdditionalWalletErrorModal from "./modals/WalletSigninFlow/AditionalWalletError";
import VerificationSuccessfulModal from "./modals/WalletSigninFlow/VerificationSuccessful";
import ExportDataModal from "./modals/ExportDataModal";

const MODALS = {
  PublishCommunityModal,
  PublishSeasonModal,
  AbandonChallengeModal,
  AboutUsModal,
  ActivateListingModal,
  ActivityDetailsModal,
  AddListingConditionModal,
  ArchiveListingModal,
  BenefitsCommunityCreationModal,
  CancelDonationModal,
  ClaimRewardFlowModal,
  BeforeYouLeaveCommunityModal,
  BeforeYouLeaveSeasonModal,
  CommitToChallengeModal,
  CompleteSeasonModal,
  ConfirmContributionModal,
  ConnectEmailModal,
  ConnectSocialMediasModal,
  DisconnectSocialProfileModal,
  DiscourseConnectModal,
  GitcoinConnectModal,
  DonateModal,
  FilterModal,
  FundChallengeModal,
  InviteAdministratorsModal,
  ManagePendingContributionModal,
  PinCodeVerificationModal,
  PublishChallengeConfirmationModal,
  RemoveAdminModal,
  RewardFoundModal,
  SelectFundingWalletModal,
  ShareSocialMediaModal,
  SignInFlowModal,
  UpdateProfileFlowModal,
  UpdateProfileModal,
  WalletConnectorModal,
  WalletSigninFlow,
  VerificationSuccessfulModal,
  AdditionalWalletErrorModal,
  CommunityEditorModal,
  SeasonEditorModal,
  ListingEditorModal,
  ExportDataModal,
  ListingShowModal,
  SeasonEligibilityModal,
};

const useModalsManager = (modalClassesArray) => {
  const { hideModal } = useStoreActions((actions) => actions.modals);
  const modals = useStoreState((state) => state.modals.modals);
  const index = modals.length - 1;

  if (index === -1) {
    return <></>;
  }

  const _hideModal = () => {
    virtualPageView({ pathname: "" });
    hideModal();
  };

  const modalElements = modals.map((modalData, index) => {
    const { modalName, modalProps } = modalData;
    const ModalKlass = modalClassesArray[modalName];
    return <ModalKlass {...modalProps} hideModal={_hideModal} key={modalName + index} />;
  });

  return modalElements;
};

export default function ModalsManager() {
  const modalElements = useModalsManager(MODALS);

  return <> {modalElements} </>;
}
