import { useStoreActions, useStoreState } from "easy-peasy";
import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useManagePage from "../ManageCommunities/useManagePage";
import { VERIFICATION_MAGNITUDE } from "./Steps/TypeSelectionStep/Forms";
import useListingTemplates from "./Steps/useListingTemplates";

const uid = () => Math.floor(Math.random() * 1000000);

const IMPORT_JOBS = [{ key: uid() }];

export const INITIAL_STATE = {
  conditionFields: [],
  conditions: {},
  contribution_app: "snapshot_app",
  import_jobs: IMPORT_JOBS,
  variables: {},
  community_tag_ids: [],
  verification_magnitude: VERIFICATION_MAGNITUDE[0],
};

const formatListing = (listing) => {
  return Object.assign({}, INITIAL_STATE, {
    ...listing,
    community_tag_ids: listing.community_tags.map(({ id }) => id),
    ...extractActivityFields(listing),
  });
};

const extractActivityFields = (activity) => {
  const conditions = activity.conditions || {};

  return {
    ...activity,
    ...verificationSpeedFormat(conditions),
    import_jobs: activity.import_jobs,
    conditionFields: getConditionFields(conditions),
  };
};

const getConditionFields = (obj) => {
  return Object.keys(obj).filter((key) => key !== "verification_speed");
};

const verificationSpeedFormat = ({ verification_speed }) => {
  if (!verification_speed) {
    return {};
  }

  const [_, verification_value, verification_magnitude] = verification_speed.split(" ");
  return {
    verification_value,
    verification_magnitude,
  };
};

export const ListingEditorContext = createContext({
  isEditing: false,
  previewMode: false,
  hideSpreadsheet: true,
  canSaveAsDraft: false,
  templateObject: { variables: [] },
  templates: [],
  communities: [],
  listing: { ...INITIAL_STATE },

  setState: () => {},
  setPreviewMode: () => {},
  submit: () => {},
  addImportJob: () => {},
  removeImportJob: () => {},
  saveAsDraft: () => {},
});

export const ListingEditorProvider = ({ children, id, communityID }) => {
  const { user } = useStoreState((state) => state.user);
  const navigate = useNavigate();
  const [listing, setState] = useState(structuredClone(INITIAL_STATE));
  const [previewMode, setPreviewMode] = useState(false);
  const { getContributionApps, createListing, editRequest, updateListing, addSpreadSheet } = useStoreActions(
    (actions) => actions.listings
  );
  const { successToast, dangerToast } = useStoreActions((actions) => actions.toasts);
  const { hideModal } = useStoreActions((actions) => actions.modals);
  const isEditing = id !== undefined;
  const { templates } = useListingTemplates();
  const { deleteSpreadsheet } = useStoreActions((actions) => actions.listings);
  const { managedCommunities } = useManagePage(["active", "draft"]);
  const { template: templateName, network: networkName } = listing;
  const networkTemplates = templates.filter((template) => template.network === networkName);
  const templateByName = networkTemplates.find(({ name }) => name === templateName) || {
    variables: [],
  };
  // Due to the way we handle this data + how we serialize to send data to the server
  // this is enough to ensure the data travels correctly as long as we always store things under
  // variables[attribute] https://i.imgur.com/IwwtZU7.png
  const templateObject = listing.id ? listing : templateByName;
  const hideSpreadsheet = !templateObject.spreadsheet_allowed;
  const canSaveAsDraft = !id;

  const onError = ({ response }) => {
    const content = response?.data?.data?.errors || response?.data?.message || "Something went wrong!";
    let message = content;
    if (typeof content === "object") {
      message = Object.values(content)[0];
    }
    dangerToast({ content: message, dismissTime: 5000 });
  };

  const saveSpreadSheets = () => {
    listing.import_jobs
      .filter(({ sheet_name, sheet_id, id }) => sheet_name && sheet_id && !id) //filter out persisted spreadsheets
      .forEach((sheet) => {
        addSpreadSheet({ ...sheet, id });
      });
  };

  const submit = async (draft = false) => {
    const embedding_personal_code = user?.verification_code;

    let data = { ...listing, draft };
    let promise;
    const { verification_value, verification_magnitude, conditionFields } = data;
    data.conditions = data.conditions || {};

    data.conditions = Object.fromEntries(
      Object.entries(data.conditions).filter(([key, _]) => conditionFields.includes(key))
    );

    if (verification_value) {
      data.conditions.verification_speed = `Within ${verification_value} ${verification_magnitude}`;
    }

    if (data.conditionFields.includes("embedding_personal_code")) {
      data.conditions.embedding_personal_code = embedding_personal_code;
    }

    if (data.custom_url === null) {
      delete data.custom_url;
    }
    const redirectToCommunity = () => navigate(`/manage/communities/${listing.community_id}?tabIndex=1`);

    if (id) {
      promise = updateListing(data).then(saveSpreadSheets).then(redirectToCommunity);
    } else {
      const { sheet_name, sheet_id } = data.import_jobs[0];
      addHiddenValues(data, templateObject);
      if (sheet_name && sheet_id) {
        data.sheet_name = sheet_name;
        data.sheet_id = sheet_id;
      }
      promise = createListing(data).then(({ message }) => {
        successToast({ content: message, timeout: 5000 });
        redirectToCommunity();
        hideModal();
      });
    }
    return promise.catch((e) => {
      const content = e.response.data.message;
      dangerToast({ content });
    });
  };

  const addImportJob = () => {
    setState((state) => {
      const import_jobs = [{ key: uid() }, ...state.import_jobs];
      return { ...state, import_jobs };
    });
  };

  const removeImportJob = (event) => {
    const { index } = event.target.dataset;
    const { import_jobs } = listing;
    const toRemove = import_jobs[index];
    const newImportJobs = import_jobs.filter((sheet) => sheet !== toRemove);

    if (toRemove.id) {
      deleteSpreadsheet(toRemove);
    }

    setState((state) => ({ ...state, import_jobs: newImportJobs }));
  };

  const onChange = (event) => {
    if (!event.target.name) return;

    const { value, name, checked, type, dataset } = event.target;
    const { conditionfield, importjobindex } = dataset;

    const actualValue = type === "checkbox" ? checked : value;
    if (conditionfield) {
      return setState((state) => {
        const conditions = { ...state.conditions };
        conditions[name] = value;
        return { ...state, conditions };
      });
    }

    if (importjobindex) {
      return setState((state) => {
        state.import_jobs[importjobindex][name] = value;
        return { ...state };
      });
    }
    setState((state) => ({ ...state, [name]: actualValue }));
  };

  const saveAsDraft = () => submit(true);

  useEffect(() => {
    id &&
      editRequest(id).then(({ listing }) => {
        const formatted = formatListing(listing);
        setState({ ...INITIAL_STATE, ...formatted });
      });
  }, [id]);

  useEffect(() => {
    getContributionApps();
  }, []);

  useEffect(() => {
    if (communityID) {
      setState((state) => ({ ...state, community_id: communityID }));
    }
  }, [communityID]);

  useEffect(() => {
    if (templateName) {
      setState((state) => ({
        ...state,
        contribution_app: templateObject.contribution_app,
        scenario_id: templateObject.scenario_id,
        verification_method: templateObject.verification_method,
        spreadsheet_allowed: templateObject.spreadsheet_allowed,
        conditions_allowed: templateObject.conditions_allowed,
      }));
    }
  }, [templateName, networkName]);

  useEffect(() => {
    if (networkName && networkTemplates.length) {
      setState((state) => ({ ...state, template: networkTemplates[0].name }));
    }
  }, [networkName]);

  useEffect(() => {
    if (templateByName.name) {
      setState((state) => ({
        ...state,
        scenario_sheet_header: templateByName.scenario_sheet_header,
        template_name: templateByName.name,
        template_description: templateByName.template_description,
      }));
    }
  }, [templateByName.name]);

  return (
    <ListingEditorContext.Provider
      value={{
        isEditing,
        listing,
        previewMode,
        setState,
        setPreviewMode,
        submit,
        addImportJob,
        removeImportJob,
        templates,
        onChange,
        communities: managedCommunities,
        hideSpreadsheet,
        templateObject,
        saveAsDraft,
        canSaveAsDraft,
      }}
    >
      {children}
    </ListingEditorContext.Provider>
  );
};

const addHiddenValues = (listing, templateObject) => {
  templateObject.variables
    .filter(({ type }) => type == "hidden")
    .forEach(({ key, value }) => {
      listing[key] = value;
    });
  return listing;
};
