import {
  Box,
  breakpoints,
  IconSvg,
  ListingConditions,
  Loader,
  Modal,
  Text,
  TokenReward,
} from "@thrivecoin/ui-components";
import { capitalizeFirstLetter } from "@utils/capitalizeFirstLetter";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useFavoriteListings from "../Season/BodySeason/WaysToContribute/useFavoriteListings";
import useWaysToContribute from "../Season/BodySeason/WaysToContribute/useWaysToContribute";

const ListingTags = ({ listing }) => {
  if (listing?.community_tags?.length > 0) {
    return (
      <Box spacing="mt-2" sm-spacing="mt-0">
        <Text extraBold>Tags</Text>
        <TagsWrapper flex wrap="wrap" gap="6px">
          {listing.community_tags.map((item, index) => (
            <TagBox key={index}>
              <Text bold typography="body/small" color="02Primary0">
                {item}
              </Text>
            </TagBox>
          ))}
        </TagsWrapper>
      </Box>
    );
  }

  return <></>;
};

const ListingShowModal = ({ hideModal, listingID }) => {
  const [loading, setLoading] = useState(true);
  const [listing, setListing] = useState(null);
  const { isSocialProfileConnected } = useWaysToContribute();
  const verification = listing?.verification_method && capitalizeFirstLetter(listing?.verification_method);
  const { isDesktop, isMiniTablet, isMobile } = useStoreState((state) => state.media);
  const { getListingDetails } = useStoreActions((actions) => actions.listings);
  const toggleFavorite = useFavoriteListings();
  const { confirmed } = useStoreState((state) => state.user.user);

  const modalPadding = isMobile ? "50px 16px 64px" : isMiniTablet ? "64px 16px" : "64px";
  const modalMarginTop = isMobile && "200px";

  const contributionsPerUser = listing?.unlimited_contributions_per_user
    ? ""
    : ` out of ${listing?.contributions_per_user}`;

  const communitySlug = listing?.community_slug || listing?.community_id;
  const showTakeAction =
    !listing?.completed && listing?.custom_url && isSocialProfileConnected(listing?.contribution_app);

  const completions = () => (
    <Text bold typography="body/small">
      {listing?.contributions_count || 0 + contributionsPerUser}
    </Text>
  );

  const onToggleFavorite = (event) => {
    return toggleFavorite(event).then(() => setListing((prev) => ({ ...prev, favorite: !prev.favorite })));
  };

  useEffect(() => {
    getListingDetails(listingID)
      .then((data) => {
        setListing(data.listing);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <Modal
      open
      paddingBody={modalPadding}
      marginTop={modalMarginTop}
      bgColor="02PrimaryGrey150"
      onClose={hideModal}
      maxWidth="681px"
    >
      {loading ? (
        <Box spacing="m-a">
          <Loader $size="44px" />
        </Box>
      ) : (
        <Box color="02Primary500">
          <Text typography="heading/medium" textAlign="center">
            Share Contribution
          </Text>
          <Text textAlign="center" spacing="mt-4 mb-6">
            If you wish to both explore and monitor this contribution simultaneously you can, we auto-saved this
            contribution as favorite.
          </Text>
          <Box spacing="p-4" bgColor="#fff" borderRadius="8px">
            <Box flex alignItems="center" gap="12px">
              <Image src={listing?.image_url} />
              <Box grow={1}>
                <Text bold>{listing.name}</Text>
                {showTakeAction && (
                  <StyledLink target="_blank" href={listing?.custom_url}>
                    (Take Action <StyledIcon icon="circle-up-right" width="16px" />)
                  </StyledLink>
                )}
              </Box>
              {confirmed && (
                <CircledIcon id={listingID} favorite={listing.favorite} toggleFavorite={onToggleFavorite} />
              )}
            </Box>
            <Separator />
            <RewardBox>
              <TokenReward
                hideText
                color="02Primary500"
                completed={listing?.completed}
                amount={listing?.reward_amount}
                token={listing?.reward_token}
                fontSize="30px"
              />
              {!isMobile && confirmed && (
                <Box flex alignItems="center" justifyContent="center">
                  {completions()}
                </Box>
              )}
            </RewardBox>
            <Separator />
            <ContentWrapper flex>
              <ContentBox flex direction="column" gap="12px">
                <Text extraBold>Details</Text>
                <Box flex alignItems="center" gap="8px">
                  <Text typography="body/small" bold>
                    Community:
                  </Text>
                  <Link to={`/communities/${communitySlug}`} target="_blank" rel="noopener noreferrer">
                    <Text typography="body/small" bold color="01Link">
                      {listing?.community_name}
                    </Text>
                  </Link>
                </Box>
                {confirmed && (
                  <Box flex alignItems="center" gap="8px">
                    <Text typography="body/small" bold>
                      Completions:
                    </Text>
                    {completions()}
                  </Box>
                )}
                <Box flex alignItems="center" gap="8px">
                  <Text bold typography="body/small">
                    Completions Check:
                  </Text>
                  <Text bold typography="body/small">
                    {verification}
                  </Text>
                </Box>
                {!isMobile && <ListingTags listing={listing} />}
              </ContentBox>
              <ContentBox flex direction="column" gap="12px">
                <Text extraBold>Conditions</Text>
                <StyledConditions>
                  <ListingConditions
                    completed={listing?.completed}
                    isDesktop={isDesktop}
                    conditions={listing?.conditions}
                  />
                </StyledConditions>
              </ContentBox>
              {isMobile && <ListingTags listing={listing} />}
            </ContentWrapper>
            {!isMobile && listing?.description && (
              <>
                <Separator />
                <Description typography="body/small" bold>
                  {listing?.description}
                </Description>
              </>
            )}
          </Box>
        </Box>
      )}
    </Modal>
  );
};

const CircledIcon = ({ id, favorite, toggleFavorite }) => {
  const [loading, setLoading] = useState(false);
  const bookIcon = favorite ? "bookmark-filled" : "bookmark";

  const _toggleFavorite = (event) => {
    setLoading(true);
    const promise = Promise.resolve(toggleFavorite(event));
    promise.finally(() => {
      setLoading(false);
    });
  };

  if (loading) {
    return <Loader $size="18px" />;
  } else {
    return (
      <_CircledIcon
        color="02Primary500"
        data-id={id}
        data-favorite={favorite}
        onClick={_toggleFavorite}
        icon={bookIcon}
        width="18px"
      />
    );
  }
};

const Image = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const StyledLink = styled.a`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors["01Link"]};
  display: inline-flex;
  align-items: center;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  svg {
    margin-left: 2px;
  }
`;

const StyledIcon = styled(IconSvg)`
  color: ${({ theme, color }) => theme.colors[color ? color : "01Link"]};
  fill: ${({ theme, fill }) => theme.colors[fill ? fill : "01Link"]};
  path {
    color: ${({ theme, color }) => theme.colors[color ? color : "01Link"]};
    fill: ${({ theme, fill }) => theme.colors[fill ? fill : "01Link"]};
  }
`;

const _CircledIcon = styled(IconSvg)`
  width: 14px;
  height: 26px;
  padding: 8px 14px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors["01Primary300"]};
  cursor: pointer;
`;

const Separator = styled(Box)`
  height: 1px;
  background: ${({ theme }) => theme.colors["02PrimaryGrey150"]};
  margin: 16px 0;
`;

const RewardBox = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const StyledConditions = styled(Box)`
  & > div {
    flex-direction: column;
    gap: 12px;

    & > div:first-child {
      display: none;
    }

    & > div {
      margin-right: unset !important;
      color: ${({ theme }) => theme.colors["02Primary500"]};

      & > * {
        color: inherit;
      }
    }
  }
`;

const ContentWrapper = styled(Box)`
  gap: 14px;

  @media ${breakpoints.mobile} {
    flex-direction: column;
    gap: 16px;
  }
`;

const ContentBox = styled(Box)`
  flex: 1;
`;

const TagsWrapper = styled(Box)`
  row-gap: 8px;
  margin-top: 8px;
`;

const TagBox = styled(Box)`
  border-radius: 4px;
  padding: 2px 6px;
  background: ${({ theme }) => theme.colors["01Secondary500"]};
`;

const Description = styled(Text)`
  word-break: break-word;
`;

export default ListingShowModal;
